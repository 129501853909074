import React, { useEffect, useState } from "react";
import ClickForm from "./ClickForm";
import axios from "axios";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import config from "./utils/config";
import _ from "lodash";
import PurchaseForm from "./PurchaseForm";
import { useParams } from "react-router-dom";
import LoaderComponent from "./LoaderComponent";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Select from "react-select";
import ClaimForm from "./ClaimForm";
import { useNavigate } from "react-router-dom";
import { hover } from "@testing-library/user-event/dist/hover";

const OrderForm = () => {
  const [orderDate, setorderDate] = useState(dayjs(new Date()));
  const [openClickForm, setopenClickForm] = useState(false);
  const [shopName, setshopName] = useState("");
  const [formData, setformData] = useState(null);
  const [labelNames, setlabelNames] = useState({});
  const [inputValue, setinputValue] = useState();
  const [displayMessage, setdisplayMessage] = useState(false);
  const [openPurchaseForm, setopenPurchaseForm] = useState(false);
  const [purchaseData, setpurchaseData] = useState(null);
  const { userId, partnerId } = useParams();
  const [predecessorId, setpredecessorId] = useState(0);
  const [email, setemail] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [showErrorMsg, setshowErrorMsg] = useState(false);
  const [showEmailErrorMsg, setShowEmailErrorMsg] = useState(false);
  const [showDateErrorMsg, setshowDateErrorMsg] = useState(false);
  const [shopNamesList, setShopNamesList] = useState([]);
  const [showShopsSelect, setShowShopsSelect] = useState(false);
  const navigate = useNavigate();

  const [myTextStyle, setMyTextStyle] = useState({
    color: "#fff",
    backgroundColor: "#000",
  });

  const [myBtnStyle, setMyBtnStyle] = useState({
    color: "#fff",
    backgroundColor: "#000",
  });

  useEffect(() => {

    if(config.gold_color_partners.includes(partnerId)){
      import(`../styles/danish.scss`).then(() => {
        console.log(`Imported CSS`);
      }).catch((error) => {
        console.error(`Error importing CSS: `, error);
      });
    }

    if(!config.gold_color_partners.includes(partnerId)){
      import(`../styles/normalUsers.scss`).then(() => {
        console.log(`Imported CSS`);
      }).catch((error) => {
        console.error(`Error importing CSS: `, error);
      });
    }

    if (config.swedish_partner.includes(partnerId)) {
      setlabelNames(config.swedishText);
    } else if (config.danish_partner.includes(partnerId)) {
      setlabelNames(config.danishText);
    } else if (config.norweign_partner.includes(partnerId)) {
      setlabelNames(config.norwegianText);
    } else if (config.swedish_remember_partner.includes(partnerId)) {
      setlabelNames(config.swedish_remember_partner_text);
    } else if (config.norweign_remember_partner.includes(partnerId)){
      setlabelNames(config.norweign_remember_partner_text);
    } else if (config.danish_remember_partner.includes(partnerId)){
      setlabelNames(config.danish_remember_partner_text);
    } else {
      setlabelNames(config.englishText);
    }

    if (config.red_color_partners.includes(partnerId)) {
      setMyTextStyle({
        color: "#fff",
        backgroundColor: "#ec0000",
      });
      setMyBtnStyle({
        color: "#fff",
        backgroundColor: "#ec0000",
      });
    } else if (config.gold_color_partners.includes(partnerId)) {
      setMyTextStyle({
        // color: "#fff",
        backgroundColor: "#fff",
      });
      setMyBtnStyle({
        // borderWidth: "1px",
        // borderStyle: "solid",
        // minHeight: "2.375rem",
        // padding: "9px 33px",
        // backgroundColor: "transparent",
        // borderColor: "#000",
        // color: "#000",
      });
    }
  }, []);

  const handleEmailChange = (e) => {
    const enteredemail = e.target.value;
    setemail(enteredemail);
    setShowEmailErrorMsg(!validateEmail(enteredemail));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const loadOptions = () => {
    const formattedDate = dayjs(orderDate).format("YYYY-MM-DD HH:mm:ss");
    // console.log("date----------", formattedDate);

    axios
      .get(
        `${config.SERVER_URL}/findshopnamebyorderdate?userid=${userId}&partnerid=${partnerId}&orderDate=${formattedDate}`
      )
      .then((resp) => {
        if (resp.data.shopnames) {
          setShowShopsSelect(false);
          setShopNamesList(resp.data.shopnames);
          // console.log("Inside setting shops data");
        } else {
          setShopNamesList([]);
          setShowShopsSelect(true);
        }
      })
      .catch((error) => {
        navigate(`/apology/${userId}/${partnerId}`);
        // console.log(error);
      });
  };

  const revertErrorsToFalse = () => {
    setShowEmailErrorMsg(false);
    setshowErrorMsg(false);
    setshowDateErrorMsg(false);
  };

  const handleSubmit = () => {
    const formattedDate = dayjs(orderDate).format("YYYY-MM-DD HH:mm:ss");
    // console.log("date----------", formattedDate);

    revertErrorsToFalse();

    if (
      _.isEmpty(shopName) &&
      (formattedDate === "Invalid Date" || _.isEmpty(orderDate)) &&
      _.isEmpty(email) &&
      !validateEmail(email)
    ) {
      setshowErrorMsg(true);
      setShowEmailErrorMsg(true);
      setshowDateErrorMsg(true);
    } else if (_.isEmpty(shopName) && _.isEmpty(email)) {
      setShowEmailErrorMsg(true);
      setshowErrorMsg(true);
    } else if (_.isEmpty(shopName)) {
      setshowErrorMsg(true);
    } else if (_.isEmpty(email)) {
      setShowEmailErrorMsg(true);
    } else if (!validateEmail(email)) {
      setShowEmailErrorMsg(true);
    } else if (formattedDate === "Invalid Date" || _.isEmpty(orderDate)) {
      setshowDateErrorMsg(true);
      return;
    } else {
      setisLoading(true);
      setdisplayMessage(false);
      setopenClickForm(false);
      setopenPurchaseForm(false);

      axios
        .post(
          `${config.SERVER_URL}/fetchallactivitybyuseridpartneridorderdateshopname?userId=${userId}&orderDate=${formattedDate}&partnerId=${partnerId}&shopName=${shopName}`
        )
        .then((response) => {
          setisLoading(false);
          if (!_.isEmpty(response.data.Activities)) {
            setformData(response.data.Activities.body[0]);
            if (response.data.Activities.body[1]) {
              setopenPurchaseForm(true);
              setpurchaseData(response.data.Activities.body[1]);
              setpredecessorId(response.data.Activities.body[1].predecessorId);
            } else {
              setopenClickForm(true);
            }
          } else setdisplayMessage(true);
        })
        .catch((error) => {
          navigate(`/apology/${userId}/${partnerId}`);
          setisLoading(false);
          console.error(error);
        });
    }
  };

  return (
    <div
      className="page-content"
      style={{
        padding: config.gold_color_partners.includes(partnerId)
          ? "0px"
          : "",
      }}
    >
      <div className={
              config.gold_color_partners.includes(partnerId)
                ? "container ml-0 p-0-5"
                : "container"
            }>
        {!config.gold_color_partners.includes(partnerId) && (
          <div className="wizard-heading">{labelNames["Support Form"]}</div>
        )}
        <section
          id="form-total-p-0"
          role="tabpanel"
          aria-labelledby="form-total-h-0"
          className=""
          aria-hidden="false"
        >
          <div
            className={
              config.gold_color_partners.includes(partnerId)
                ? "reward-support-form-heading"
                : "steps-heading"
            }
            style={myTextStyle}
          >
            <div>
              {config.gold_color_partners.includes(partnerId) ? (
                <h1>{labelNames["Order Details"]}</h1>
              ) : (
                <h3>{labelNames["Order Details"]}</h3>
              )}
            </div>
          </div>

          <div
            className={
              config.gold_color_partners.includes(partnerId)
                ? "reward-support-form-item"
                : "row mb-3"
            }
          >
            <label htmlFor="colFormLabel" className="col-sm-2 col-form-label">
              {labelNames["Email"]}:
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                // className="form-control"
                id="email"
                onChange={(e) => handleEmailChange(e)}
              />
              {showEmailErrorMsg && (
                <p className="error-message">
                  {email && !validateEmail(email)
                    ? "Invalid Email Formate"
                    : "This field is required"}
                </p>
              )}
            </div>
          </div>

          <div
            className={
              config.gold_color_partners.includes(partnerId)
                ? "reward-support-form-item"
                : "row mb-3"
            }
          >
            <label htmlFor="colFormLabel" className="col-sm-2 col-form-label">
              {labelNames["Date"]}:
            </label>
            <div 
              className="col-sm-10" 
              id={
                config.gold_color_partners.includes(partnerId)
                  ? "input-border-none"
                  : "overflow-hide"
              }>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    value={orderDate}
                    onChange={(neworderDate) => {
                      setshowDateErrorMsg(false);
                      setorderDate(neworderDate);
                      setShopNamesList([]);
                      setopenClickForm(false);
                      setshopName("");
                      setopenPurchaseForm(false);
                    }}
                    // ampm={false}
                    format="YYYY-MM-DD"
                    Autocomplete={false}
                    disableFuture={true}
                    className={
                      config.gold_color_partners.includes(partnerId)
                        ? "reward-support-form-item-date gold-form-control"
                        : "w-100"
                    }
                  />
                  {showDateErrorMsg && (
                    <p className="error-message">This field is required</p>
                  )}
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>

          {shopNamesList.length === 0 && (
            <button
              type="submit"
              className={
                config.gold_color_partners.includes(partnerId)
                  ? "reward-support-form-item-button gold-submit-btn"
                  : "submit-btn"
              }
              style={myBtnStyle}
              onClick={loadOptions}
            >
              {labelNames["Next"]}
            </button>
          )}

          {showShopsSelect && (
            <>
              <p className="error-message">
                {labelNames["click_not_present_message"]}
              </p>
            </>
          )}
          {shopNamesList.length !== 0 && (
            <>
              <div
                className={
                  config.gold_color_partners.includes(partnerId)
                    ? "reward-support-form-item gold-form-control"
                    : "row mb-3"
                }
              >
                <label
                  htmlFor="colFormLabel"
                  className="col-sm-2 col-form-label"
                >
                  {labelNames["Shop Name"]}:
                </label>
                <div className="col-sm-10">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={shopNamesList}
                    value={shopName}
                    onChange={(event, newValue) => {
                      setshopName(newValue);
                      setopenClickForm(false);
                      setopenPurchaseForm(false);
                    }}
                    renderInput={(params) => (
                      <TextField {...params}  />
                    )}
                    className={
                      config.gold_color_partners.includes(partnerId)
                        ? "reward-support-form-item-shopName gold-form-control"
                        : ""
                    }
                  />

                  {showErrorMsg && (
                    <p className="error-message">This field is required</p>
                  )}
                </div>
              </div>

              {!openClickForm && (
                <button
                  type="submit"
                  className={
                    config.gold_color_partners.includes(partnerId)
                      ? "reward-support-form-item-button gold-submit-btn"
                      : "submit-btn"
                  }
                  style={myBtnStyle}
                  onClick={handleSubmit}
                >
                  {labelNames["Submit"]}
                </button>
              )}
            </>
          )}
        </section>
        {isLoading && <LoaderComponent />}
        {openClickForm && (
          // <ClickForm
          //   formData={formData}
          //   partnerId={partnerId}
          //   predecessorId={predecessorId}
          //   email={email}
          // />
          <ClaimForm
            partnerId={partnerId}
            shopName={formData.shopName}
            email={email}
            activityId={formData.activityId}
            formData={formData}
            clickDate={dayjs(formData.timeStamp)}
            myBtnStyle={myBtnStyle}
          />
        )}
        {displayMessage && <h3>No Click Found </h3>}
        {openPurchaseForm && (
          <PurchaseForm
            partnerId={partnerId}
            purchaseData={purchaseData}
            myTextStyle={myTextStyle}
          />
        )}
      </div>
    </div>
  );
};

export default OrderForm;
