import localforage from "localforage";

const config = {
  SERVER_URL: "https://supportbackend.testm2msaas.com",
  localforage: localforage.createInstance({
    name: "m2m-support",
  }),
  englishText: {
    "Support Form": "Support Form",
    "Order Details": "Order Details",
    Click: "Click",
    "Purchase Details":
      "Purchase Details",
    "Partner ID": "Partner ID",
    "User ID": "User ID",
    Email: "Email",
    "Shop Name": "Shop Name",
    Date: "Date",
    Submit: "Submit",
    "Purchase ID": "Purchase ID",
    "Purchase Date": "Purchase Date",
    "Order Number": "Order Number",
    "Order Value": "Order Value",
    "Order Date": "Order Date",
    "Expected Commission": "Commission Value",
    "Reward Category": " Reward Category",
    "Reward Commission Percentage": "Reward Commission Percentage",
    Next: "Next",
    claim_registered:
      "Your purchase is now registered and you will see the purchase in your account within 1-2 days",
    click_not_present_message:
      "We cannot find any purchases made through our system on this date. Please try again with a new date or contact support here",
    purchase_registered:
      "Your Purchase has been registered to our system,cashback amount will be added your account",
    "Reward Commission": "Reward Commission",
    "File Upload": "File Upload"
  },
  swedishText: {
    "Support Form": "Supportformulär",
    "Order Details": "Order information",
    Click: "Klick information",
    "Purchase Details":
      "Köpinformation",
    "Partner ID": "Partner ID",
    "User ID": "Användare ID",
    Email: "E-mail",
    "Shop Name": "Butiksnamn",
    Date: "Köpdatum",
    Submit: "Skicka",
    "Purchase ID": " Köp ID",
    "Purchase Date": "Köpdatum",
    "Order Number": "Ordernummer från butik",
    "Order Value": "Ordervärde exkl, moms",
    "Order Date": "Orderdatum",
    "Expected Commission": "Förväntad ersättning",
    "Reward Category": "Belöningskategori",
    "Reward Commission Percentage": "BelöningsProvisionsProcent",
    Next: "Sök",
    claim_registered:
      "Ditt köp är nu registrerat. Köpet bekräftas så snart butiken godkänt köpet, normalt inom 60 dagar från köpdatumet. För reseköp gäller ca 60 dagar efter det att resan avslutats.",
    click_not_present_message:
      "Vi kan inte hitta något köp som har genomförts via vårt system på detta datum. Vänligen försök igen med ett nytt datum eller kontakta support genom att stänga detta fönster och välj Övriga frågor i menyn vid Ämne.",
    purchase_registered:
      "Ditt köp har registrerats i vårt system, återbetalningsbeloppet kommer att läggas till ditt konto",
    "Reward Commission": "Belöningskommission",
    "File Upload": "Ladda upp orderbekräftelse"
  },

  norwegianText: {
    "Support Form": "Kontaktskjema",
    "Order Details": "Ordredetaljer",
    Click: " Klikk",
    "Purchase Details":
      "Kjøpsdetaljer",
    "Partner ID": "Partner ID",
    "User ID": "Bruker-ID",
    Email: "E-post",
    "Shop Name": "Butikk",
    Date: "Kjøpsdato",
    Submit: "Send",
    "Purchase ID": "Kjøps-ID",
    "Purchase Date": "Kjøpsdato",
    "Order Number": "Ordrenummer fra butikken",
    "Order Value": "Ordreverdi ekskl, moms",
    "Order Date": "Bestillingsdato",
    "Expected Commission": "Forventet kompensasjon",
    Next: "Søk",
    claim_registered:
      "Kjøpet ditt er nå registrert. Kjøpet bekreftes så snart butikken godkjenner kjøpet, normalt innen 60 dager fra kjøpsdato. For reisekjøp gjelder ca 60 dager etter at reisen er avsluttet.",
    click_not_present_message:
      "Vi kan ikke finne noe kjøp som er gjennomført via vårt system på denne datoen. Vennligst prøv igjen med en ny dato eller kontakt support ved å lukke dette vinduet og velge Andre spørsmål fra menyen under Emne.",
    purchase_registered:
      "Ditt kjøp har blitt registrert i vårt system, cashback beløp vil bli lagt til kontoen din",
    "Reward Commission": "Belønningskommisjon",
    "File Upload": "Last opp ordrebekreftelse"
  },

  danishText: {
    "Support Form": "Supportformular",
    "Order Details": "Ordredetaljer",
    Click: "Klikinformation",
    "Purchase Details":
      "Købsinformation",
    "Partner ID": "Partner ID",
    "User ID": "Bruger ID",
    Email: "E-mail",
    "Shop Name": "Butiksnavn",
    Date: "Dato",
    Submit: "Indsend",
    "Purchase ID": " Købs ID",
    "Purchase Date": "Købsdato",
    "Order Number": "Ordrenummer fra butikken",
    "Order Value": "Ordreværdi",
    "Order Date": "Bestillingsdato",
    "Expected Commission": "Forventet kompensation.",
    "Reward Commission": "Belønningskommission",
    "Reward Commission Percentage": "Reward Commission Percentage",
    Next: "Next",
    claim_registered:
      "Dit køb er nu registreret. Købet bekræftes så snart butikken godkender købet, normalt inden for 60 dage fra købsdatoen. Ved rejsekøb gælder cirka 60 dage efter rejsens afslutning.",
    click_not_present_message:
      "We cannot find any purchases made through our system on this date. Please try again with a new date or contact support here",
    purchase_registered:
      "Your Purchase has been registered to our system,cashback amount will be added your account",
    "File Upload": "Upload ordrebekræftelse"
  },
  swedish_remember_partner_text: {
    "Support Form": "Supportformulär",
    "Order Details": "Order-information",
    Click: "Klick-information",
    "Purchase Details":
      "Köpinformation",
    "Partner ID": "Partner-ID",
    "User ID": "Användar-ID",
    Email: "E-mail",
    "Shop Name": "Butik",
    Date: "Köpdatum",
    Submit: "Skicka",
    "Purchase ID": " Köp-ID",
    "Purchase Date": "Köpdatum",
    "Order Number": "Ordernummer",
    "Order Value": "Ordervärde (exkl. moms)",
    "Order Date": "Orderdatum",
    "Expected Commission": "Förväntad rabatt",
    "Reward Category": "Reward-kategori",
    "Reward Commission Percentage": "Reward-rabatt i procent",
    Next: "Sök",
    claim_registered:
      "Ditt köp är nu registrerat, du kommer att se det på ditt konto inom 1-2 dagar",
    click_not_present_message:
      "Vi kan inte hitta något köp som har gjorts via vårt system på detta datum. Vänligen försök igen med ett nytt datum eller kontakta support genom att stänga detta fönster och välj Övriga frågor i menyn vid Ämne.",
    purchase_registered:
      "Ditt köp har registrerats i vårt system, återbetalningsbeloppet kommer att läggas till på ditt konto",
    "Reward Commission": "Reward-rabatt",
    "File Upload": "Ladda upp fil"
  },

  norweign_remember_partner_text: {
    "Support Form": "Kontaktskjema",
    "Order Details": "Ordredetaljer",
    Click: " Klikk",
    "Purchase Details":
      "Kjøpsdetaljer",
    "Partner ID": "Partner-ID",
    "User ID": "Bruker-ID",
    Email: "E-post",
    "Shop Name": "Butikk",
    Date: "Kjøpsdato",
    Submit: "Send",
    "Purchase ID": "Kjøps-ID",
    "Purchase Date": "Kjøpsdato",
    "Order Number": "Ordrenummer",
    "Order Value": "Ordreverdi (ekskl. moms)",
    "Order Date": "Bestillingsdato",
    "Expected Commission": "Forventet rabatt",
    Next: "Søk",
    claim_registered:
      "Ditt kjøp er nå registrert, og du vil se kjøpet på kontoen din innen 1-2 dager",
    click_not_present_message:
      "Vi kan ikke finne noe kjøp som er gjennomført via vårt system på denne datoen. Vennligst prøv igjen med en ny dato eller kontakt support ved å lukke dette vinduet og velge Andre spørsmål fra menyen under Emne.",
    purchase_registered:
      "Ditt kjøp har blitt registrert i vårt system, rabattbeløp vil bli lagt til kontoen din",
    "Reward Commission": "Reward-rabatt",
    "File Upload": "Last opp fil"
  },

  danish_remember_partner_text: {
    "Support Form": "Kontaktformular",
    "Order Details": "Ordredetaljer",
    Click: "Klik",
    "Purchase Details":
      "Købsinformation",
    "Partner ID": "Partner ID",
    "User ID": "Bruger ID",
    Email: "E-mail",
    "Shop Name": "Butiksnavn",
    Date: "Dato",
    Submit: "Send",
    "Purchase ID": " Købs ID",
    "Purchase Date": "Købsdato",
    "Order Number": "Ordrenummer",
    "Order Value": "Ordreværdi",
    "Order Date": "Bestillingsdato",
    "Expected Commission": "Forventet rabat.",
    "Reward Commission": "Reward rabat",
    "Reward Commission Percentage": "Reward rabat i procent",
    Next: "Næste",
    claim_registered: "Dit køb er nu registreret og vil kunne ses på din konto indenfor 1-2 dage",
    click_not_present_message: "Vi kan ikke finde nogle transaktioner i vores system på den valgte dato. Prøv venligst igen med en anden dato eller kontakt venligst support ved at vælge “Andre spørgsmål” under emnefeltet.",
    purchase_registered: "Dit køb er registreret i vores system, og rabatten vil blive tilføjet til din konto.",
    "File Upload": "Upload fil"
  },
  swedish_partner: ["17", "18","21", "22"],
  norweign_partner: ["19", "20", "23", "25"],
  danish_partner: ["24", "26"],
  norweign_remember_partner: ["5", "13"],
  swedish_remember_partner: ["14"],
  danish_remember_partner: ["12"],
  red_color_partners: ['17','18','25','26'],
  gold_color_partners: ['5', '12', '13', '14']
};

export default config;
