import React from "react";
import spinner from "../images/Loader.gif";
import "../styles/loader.scss"

export default function LoaderComponent() {
  return (
    <div
      className="loader-img-div"
    //   style={{ top: this.props.isQuickStatus ? "10%" : "" }}
    >
      <img className="loader-img" src={spinner} alt="loading...."></img>
    </div>
  );
}
