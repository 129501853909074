import "./App.css";
import "./styles/orderForm.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./components/Dashboard";
import OrderForm from "./components/OrderForm";
import LoginForm from "./components/LoginForm";
import PrivateRoute from "./components/PrivateRoute";
import PageNotFound from "./components/PageNotFound";
import SuccessPage from "./components/SuccessPage";
import ApologyScreen from "./components/ApologyScreen";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginForm />} />
          <Route
            path="/claims"
            element={<PrivateRoute component={Dashboard} />}
          />
          <Route path="/orderForm/:userId/:partnerId" element={<OrderForm />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/success/:message/:partnerId" element={<SuccessPage />} />
          <Route path="/apology/:userId/:partnerId" element={<ApologyScreen />} />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </>
  );
}

export default App;
