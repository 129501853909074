import React, { useState, useEffect } from "react";
import config from "./utils/config";
import axios from "axios";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { toast } from "react-toastify";

const MissingClaimForm = (props) => {
  const [labelNames, setlabelNames] = useState(config.englishText);
  const [orderDate, setorderDate] = useState(
    dayjs(props.data.claimedOrderDate)
  );
  const [orderNumber, setorderNumber] = useState(props.data.claimedOrderNumber);
  const [orderValue, setorderValue] = useState(props.data.orderValue);
  const [purchaseId, setPurchaseId] = useState(props.data.clickId);
  const [commisionValue, setcommisionValue] = useState(
    props.data.commisionValue
  );
  const [rewardCategory, setrewardCategory] = useState(
    props.data.rewardCategory
  );
  const [rewardCommisionPercentage, setrewardCommisionPercentage] = useState(
    props.data.rewardCommisionPercentage
  );
  // const [fileData, setfileData] = useState();
  // const supportedFormats = ["jpg", "png", "jpeg", "pdf", "doc", "docx"];
  const [selectedCurrency, setSelectedCurrency] = useState(
    props.data.orderValueCurrencyCode
  );
  const [showCommissionErrorMsg, setshowCommissionErrorMsg] = useState(false);
  const [showCommisionValueErrorMsg, setShowCommisionValueErrorMsg] =
    useState(false);

  // this is for keeping track of whether any one of the order value, commission value and reward commission percentage
  const [isCommissionDetailsChanged, setIsCommissionDetailsChanged] =
    useState(false);

  const handleCurrencyChange = (event) => {
    setSelectedCurrency(event.target.value);
  };

  const notify_success = (message) => toast.success(message, {
    toastId: 'success1',
  })

  const notify_failure = (message) => toast.warning(message, {
    toastId: 'warning1',
  })

  const handleSubmit = (claim_status) => {
    const formattedDate = dayjs(orderDate).format("YYYY-MM-DD HH:mm:ss");
    // console.log("date----------", formattedDate);
    if (!rewardCommisionPercentage && !commisionValue) {
      setShowCommisionValueErrorMsg(true);
      setshowCommissionErrorMsg(true);
    }
    if (!commisionValue) {
      setShowCommisionValueErrorMsg(true);
    } else if (!rewardCommisionPercentage) {
      setshowCommissionErrorMsg(true);
    } else {
      // validating commission details which are changed if any
      let proceedToSubmit = false;

      if (isCommissionDetailsChanged) {

        let oldCommissionDetails = `${labelNames["Order Value"]}: ${props.data.orderValue}\n${labelNames["Expected Commission"]}: ${props.data.commisionValue}\n`;
        props.data.rewardCommissionType === "PERCENTAGE"
          ? oldCommissionDetails = oldCommissionDetails + `${labelNames["Reward Commission Percentage"]}: ${props.data.rewardCommisionPercentage} %`
          : oldCommissionDetails = oldCommissionDetails + `${labelNames["Reward Commission"]}: ${props.data.rewardCommisionPercentage}`;
        

        let newCommissionDetails = `${labelNames["Order Value"]}: ${orderValue}\n${labelNames["Expected Commission"]}: ${commisionValue}\n`;
        props.data.rewardCommissionType === "PERCENTAGE"
          ? newCommissionDetails = newCommissionDetails + `${labelNames["Reward Commission Percentage"]}: ${rewardCommisionPercentage} %`
          : newCommissionDetails = newCommissionDetails + `${labelNames["Reward Commission"]}: ${rewardCommisionPercentage}`;
        
        
        const commissionDataText = `Please confirm the below values:\n\nPrevious Values:\n${oldCommissionDetails}\n\nUpdated Values:\n${newCommissionDetails}`
        
        if (window.confirm(commissionDataText)) {
          proceedToSubmit = true;
        } else {
          // console.log("User has rejected from submitting claim.");
        }
      } else {
        proceedToSubmit = true;
      }

      const new_data = {
        status: claim_status,
        orderValue: orderValue,
        selectedCurrency: selectedCurrency,
        commisionValue: commisionValue,
        rewardCategory: rewardCategory,
        rewardCommisionPercentage: rewardCommisionPercentage,
        purchaseId: purchaseId,
        orderNumber: orderNumber,
        orderDate: formattedDate,
      };

      // console.log(new_data);
      // console.log(claim_status);

      if (proceedToSubmit) {
        props.updateClaimData(new_data);
      }
    }
  };

  useEffect(() => {
    if (props.partnerId === "5") {
      setlabelNames(config.swedishText);
    } else if (props.partnerId === "6") {
      setlabelNames(config.danishText);
    } else if (props.partnerId === "7") {
      setlabelNames(config.norwegianText);
    } else {
      setlabelNames(config.englishText);
    }
  });

  // const handleFileChange = (event) => {
  //   if (event.target.type === "file") setfileData(event.target.files[0]);
  // };


  const reSubmitCjClaim = () => {
    console.log("resubmitting")
    console.log(props.data)

    let formDataObj = new FormData();
    formDataObj.append('userClaim', new Blob([JSON.stringify(props.data)], { type: "application/json" }));

    axios.post(
      `${config.SERVER_URL}/resendtransactiontonetwork`,
      formDataObj,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    })
    .then((response) => {
      notify_success(response.data.message);
    })
    .catch((error) => {
      console.log(error);
      notify_failure(error.response.data.message);
    });

  }

  return (
    <section
      id="form-total-p-0"
      role="tabpanel"
      aria-labelledby="form-total-h-0"
      className="wizard-content"
      aria-hidden="false"
    >
      <div className="missing_purchase_heading bg-blue">
        <div>
          <h5>{labelNames["Purchase Details"]}</h5>
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="colFormLabel" className="col-sm-3 col-form-label">
          {labelNames["Purchase ID"]}:
        </label>
        <div className="col-sm-9">
          <input
            type="text"
            className="form-control"
            id="purchaseId"
            onChange={(event) => setPurchaseId(event.target.value)}
            value={purchaseId}
          />
        </div>
      </div>

      <div className="row mb-3">
        <label htmlFor="colFormLabel" className="col-sm-3 col-form-label">
          {labelNames["Order Number"]}:
        </label>
        <div className="col-sm-9">
          <input
            type="text"
            className="form-control"
            id="orderNumber"
            onChange={(event) => setorderNumber(event.target.value)}
            value={orderNumber}
            validators={["required"]}
            // errorMessages={["Enter the value"]}
            
          />
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="colFormLabel" className="col-sm-3 col-form-label">
          {labelNames["Order Value"]}:
        </label>
        <div className="col-sm-9" style={{ display: "flex" }}>
          <div className="col-sm-6">
            {" "}
            <input
              type="text"
              className="form-control"
              id="orderValue"
              onChange={(event) => {
                setorderValue(event.target.value);
                setIsCommissionDetailsChanged(true);
              }}
              value={orderValue ? orderValue.toString().replace(".", ",") : ""}
              required
            />
          </div>
          <div className="col-sm-6" style={{ paddingLeft: "10px" }}>
            {" "}
            <select
              className="form-control"
              id="currency"
              value={selectedCurrency}
              onChange={handleCurrencyChange}
            >
              <option value="">-- Please choose a currency --</option>
              <option value="SEK">SEK</option>
              <option value="NOK">NOK</option>
              <option value="GBP">GBP</option>
              <option value="EUR">EUR</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="colFormLabel" className="col-sm-3 col-form-label">
          {labelNames["Order Date"]}:
        </label>

        <div className="col-sm-9">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateTimePicker"]}>
              <DateTimePicker
                label="Select Date"
                value={orderDate}
                onChange={(neworderDate) => {
                  setorderDate(neworderDate);
                }}
                ampm={false}
                format="YYYY-MM-DD HH:mm"
                required
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="colFormLabel" className="col-sm-3 col-form-label">
          {labelNames["Expected Commission"]}:
        </label>
        <div className="col-sm-9">
          <input
            type="text"
            className="form-control"
            id="expectedCommission"
            onChange={(event) => {
              setcommisionValue(event.target.value);
              setShowCommisionValueErrorMsg(false);
              setIsCommissionDetailsChanged(true);
            }}
            value={
              commisionValue ? commisionValue.toString().replace(".", ",") : ""
            }
          />
          {showCommisionValueErrorMsg && (
            <p className="error-message">This field is required</p>
          )}
        </div>
      </div>
      {/* <div className="row mb-3">
        <label htmlFor="colFormLabel" className="col-sm-3 col-form-label">
          File Upload
        </label>
        <div className="col-sm-9">
          <input
            type="file"
            accept="pdf,doc,docx,jpg,png,jpeg"
            className="form-control"
            onChange={handleFileChange}
          />{" "}
          <p>{`(${supportedFormats})`}</p>
        </div>
      </div> */}
      <div className="row mb-3">
        <label htmlFor="colFormLabel" className="col-sm-3 col-form-label">
          {labelNames["Reward Category"]}:
        </label>
        <div className="col-sm-9">
          <input
            type="text"
            className="form-control"
            id="rewardCatogery"
            onChange={(event) => setrewardCategory(event.target.value)}
            value={rewardCategory}
          />
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="colFormLabel" className="col-sm-3 col-form-label">
          {props.data.rewardCommissionType === "PERCENTAGE"
            ? labelNames["Reward Commission Percentage"]
            : labelNames["Reward Commission"]}
          :
        </label>
        <div className="col-sm-9">
          <input
            type="text"
            className="form-control"
            id="rewardCommisionPercentage"
            onChange={(event) => {
              setrewardCommisionPercentage(event.target.value);
              setshowCommissionErrorMsg(false);
              setIsCommissionDetailsChanged(true);
            }}
            value={rewardCommisionPercentage}
          />
          {showCommissionErrorMsg && (
            <p className="error-message">This field is required</p>
          )}
        </div>
      </div>
      <button
        type="submit"
        className="submit-btn btn btn-sm btn-success"
        onClick={() => handleSubmit("APPROVED")}
      >
        Approve Claim
      </button>
      <button
        type="submit"
        className="submit-btn btn btn-sm btn-danger"
        style={{ marginLeft: "10px" }}
        onClick={() => handleSubmit("CANCELLED")}
      >
        Reject Claim
      </button>
      { props.data.affiliateNetwork === "CJ" ? <button
        type="submit"
        className="submit-btn btn btn-sm btn-primary"
        style={{ marginLeft: "10px" }}
        onClick={() => reSubmitCjClaim()}
      >
        Resubmit to network
      </button> : <></>}
      
    </section>
  );
};

export default MissingClaimForm;
