import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "../styles/missingPurchase.scss";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import config from "./utils/config";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';


export default function MissingPurchaseModal(props) {
  const toggleModal = () => {
    props.toggle();
  };
  const [fromDate, setfromDate] = useState(dayjs(new Date()));

  const [toDate, settoDate] = useState(dayjs(new Date()));

  const navigate=useNavigate();

  const handleFromDateChange = (date) => {
    setfromDate(date);
  };

  const handleToDateChange = (date) => {
    settoDate(date);
  };

  const handleSubmit = async () => {
    let bearer_token = "";
    await config.localforage.getItem("token").then((token) => {
      bearer_token = token;
    });
    // console.log("Current token : ", bearer_token);
    let req_config = {
      method: "get",
      url: `${config.SERVER_URL}/generateExcelReportBetweenDateRange?fromDate=${dayjs(
        fromDate
      ).format("YYYY-MM-DD")}&toDate=${dayjs(toDate).format("YYYY-MM-DD")}`,
      headers: {
        Authorization: `Bearer ${bearer_token}`,
      },
      responseType: 'blob',
    };

    axios(req_config)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `UserclaimsReportBetween_${fromDate}to${toDate}` + `.xls`
        );
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
      .catch((error) => {
        navigate(`/apology/0/0`); 
        // console.log(error);
      });
  };

  return (
    <div className="container">
      <Modal
        // className="missing_purchase_content"
        isOpen={props.open}
        toggle={props.toggleReportsModal}
        contentClassName="missing_purchase_content"
        size="100%"
      >
        <div className="container">
          <ModalHeader toggle={toggleModal}>
            <div className="">
              <span className="heading"></span>
            </div>
          </ModalHeader>
        </div>
        <div className="container">
          <ModalHeader className="missing_purchase_heading bg-blue justify-content-center">
            <div className="">
              <span className="heading">Reports</span>
            </div>
          </ModalHeader>
          <ModalBody>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="h6"
                component="div"
                className="text-uppercase"
                style={{ width: "160px" }}
              >
                Start Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Select Date"
                    value={fromDate}
                    onChange={handleFromDateChange}
                    disableFuture={true}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div style={{ display: "flex" ,marginTop:"10px", alignItems: "center" }}>
              <Typography
                variant="h6"
                component="div"
                className="text-uppercase"
                style={{ width: "160px" }}
              >
                End Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                <DatePicker
                  label="End Date"
                  value={toDate}
                  onChange={handleToDateChange}
                  disableFuture={true}
                />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}
