import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import config from './utils/config';
import { toast } from "react-toastify";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    checkAuthenticate();
  }, []);

  const checkAuthenticate = async () => {
    const value = await config.localforage.getItem('token');
    if (typeof value !== 'undefined' && value !== null) {
      setIsAuthenticated(true);
      setIsChecked(true);
    } else {
      setIsAuthenticated(false);
      setIsChecked(true);
    }
  };

  if (isAuthenticated && isChecked) {
    return <Component {...rest} />;
  } else if (isChecked) {
    toast.warning("Please login to access the page!", {
      toastId: 'warning1',
  });
    return <Navigate to="/" />;
  } else {
    return null; 
  }
};

export default PrivateRoute;
