import React from "react";
import "../styles/apologyScreen.scss";
import "font-awesome/css/font-awesome.min.css";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import _ from "lodash";
import config from "./utils/config";

const ApologyScreen = (props) => {
  const { userId , partnerId } = useParams();
  const navigate = useNavigate();
  const handleSubmit =()=>{
    if(userId !=='0' && partnerId !=='0')
    navigate(`/orderForm/${userId}/${partnerId}`);
  else
  navigate("/claims")
  }
  return (
    <div  className={
      config.gold_color_partners.includes(partnerId)
        ? "apology-screen bg-none"
        : "apology-screen"
    }>
      <div className="service-unavailable">
        <i className="fa fa-times-circle icon" aria-hidden="true"></i>
        <h1>Service Unavailable</h1>
        <p>
          We apologize for the inconvenience. Our service is currently
          unavailable. Please try again later.
        </p>
        <button className={
      config.gold_color_partners.includes(partnerId)
        ? "reward-support-form-item-button gold-submit-btn"
        : "back-button"
    } onClick={handleSubmit}>Back</button>
      </div>
    </div>
  );
};

export default ApologyScreen;
