import React, { useState, useEffect } from "react";
import "../styles/apologyScreen.scss";
import "font-awesome/css/font-awesome.min.css";
import { useParams } from 'react-router-dom';
import config from "./utils/config";

const SuccessPage=()=> {
  const { message, partnerId } = useParams();

  return (
    <div className={
      config.gold_color_partners.includes(partnerId)
        ? "apology-screen bg-none"
        : "apology-screen"
    }>
    <div className="service-unavailable">
      <i className="fa fa-check-circle check-icon" aria-hidden="true"></i>
      <h1>Success!</h1>
      <p>{decodeURIComponent(message)}</p>
      </div>
    </div>
  );
}

export default SuccessPage;