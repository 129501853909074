import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import m2menclosedlogo from "../images/m2menclosedlogo.png";
import config from "./utils/config";

export default function LoginForm() {
  const notify = () => toast.warning("Invalid Credentials", {
    toastId: 'warning1',
})
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    axios
      .get(
        `${config.SERVER_URL}/authenticateadmin?username=${data.get(
          "email"
        )}&password=${data.get("password")}`
      )
      .then((resp) => {
        config.localforage.setItem("login_status", true);
        config.localforage.setItem("token", resp.data.token);
        config.localforage.setItem("token_set_time",Date.now())
        setTimeout(() => {
          toast.success("Successfully loggedIn!", {
            toastId: 'success1',
        })
          navigate(`/claims`);
        }, 1000);
      })
      .catch((error) => {
        // notify();
        if (error.response && error.response.status === 401) {
          // If 401 error, navigate to login page
          notify();
        } 
        else
        navigate(`/apology/0/0`); 
        console.error(error);
      });
  };

  return (
    <>
      <div style={{ margin: 20 }}>
        <img src={m2menclosedlogo} alt="m2menclosedlogo" />
      </div>

      <Container component="main" maxWidth="xs">
        <Paper elevation={5}>
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              transform: "scale(0.9)",
              border: "-moz-initial",
              // border: '2px solid  grey'
            }}
          >
            <div className="login-form-header">
              <Typography component="h1" variant="h5">
                Please Sign In
              </Typography>
            </div>

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail/Username"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="success"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    </>
  );
}
