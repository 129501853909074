import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { spacing } from "@mui/system";
import config from "./utils/config";
import MissingPurchaseModal from "./MissingPurchaseModal";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Reports from "./Reports";
import MyCustomNoRowsOverlayImage from "../images/no-items-found.png";

export default function DataTable() {
  const [rows, setRows] = useState([]);
  const [partnerId, setPartnerId] = useState(5);
  const [status, setStatus] = useState("PENDING");

  const [openFormModal, setopenFormModal] = useState(false);
  const [missingPurchaseModalData, setMissingPurchaseModalData] = useState({});
  const [reportsModal, setreportsModal] = useState(false);
  const navigate = useNavigate();
  const toggle = () => {
    setopenFormModal(!openFormModal);
  };
  const toggleReportsModal = () => {
    setreportsModal(!reportsModal);
  };

  const MyCustomNoRowsOverlay = () => (
    <div className="d-flex justify-content-center">
      <img src={MyCustomNoRowsOverlayImage} alt="no-item" />
    </div>
  );

  function FilterButtons() {
    return (
      <Stack spacing={2} direction="row" sx={{ mb: 2 }}>
        <Button variant="contained" onClick={() => setStatus("PENDING")}>
          PENDING
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={() => setStatus("APPROVED")}
        >
          APPROVED
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => setStatus("CANCELLED")}
        >
          REJECTED
        </Button>
        {/* <Button
          variant="contained"
          color="secondary"
          onClick={() => setStatus("MANUAL INTERVENTION REQUIRED")}
        >
          MANUAL INTERVENTION REQUIRED
        </Button> */}
        <Button
          variant="contained"
          color="info"
          onClick={() => setreportsModal(!reportsModal)}
        >
          Reports
        </Button>
      </Stack>
    );
  }

  const handleClick = (e, values) => {
    // console.log(values);
    setMissingPurchaseModalData(values.row);
    setopenFormModal((prevOpenFormModal) => !prevOpenFormModal);
  };

  const getAllUserClaimByStatus = async () => {
    let bearer_token = "";
    await config.localforage.getItem("token").then((token) => {
      bearer_token = token;
    });
    // console.log("Current token : ", bearer_token);
    let req_config = {
      method: "get",
      url: `${config.SERVER_URL}/getalluserclaimbystatus?status=${status}`,
      headers: {
        Authorization: `Bearer ${bearer_token}`,
      },
    };

    axios(req_config)
      .then((response) => {
        setRows(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // If 401 error, navigate to login page
          navigate("/");
        } else if(error.response && error.response.status === 404 && error.response.data === "No claims found!"){
          setRows([]);
        }else {
          navigate(`/apology/0/0`);
        }
        // console.log(error);
      });
  };

  const getAllUserClaims = async () => {
    let bearer_token = "";
    await config.localforage.getItem("token").then((token) => {
      bearer_token = token;
    });
    // console.log("Current token : ", bearer_token);
    let req_config = {
      method: "get",
      url: `${config.SERVER_URL}/getalluserclaim`,
      headers: {
        Authorization: `Bearer ${bearer_token}`,
      },
    };

    axios(req_config)
      .then((response) => {
        const onlyManualInterventionRequired = response.data.filter(
          (claim) =>
            claim.isManualInterventionRequired == true &&
            claim.status !== "APPROVED" &&
            claim.status !== "CANCELLED"
        );
        setRows(onlyManualInterventionRequired);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // If 401 error, navigate to login page
          navigate("/");
        } else navigate(`/apology/0/0`);
        // console.log(error);
      });
  };

  useEffect(() => {
    if (status === "MANUAL INTERVENTION REQUIRED") {
      getAllUserClaims();
    } else {
      getAllUserClaimByStatus();
    }
  }, [status]);

  const columns = [
    {
      field: "id",
      headerName: "Claim ID",
      width: 80,
      renderCell: (cellValues) => {
        return (
          <Link
            variant="contained"
            color="primary"
            onClick={(event) => {
              handleClick(event, cellValues);
            }}
          >
            {cellValues.value}
          </Link>
        );
      },
    },
    { field: "userId", headerName: "User ID", width: 90 },
    { field: "partnerName", headerName: "Partner Name", width: 140 },
    { field: "claimedUserEmail", headerName: "Email", width: 300 },
    { field: "claimedUserFirstName", headerName: "First Name", width: 140 },
    { field: "claimedUserLastName", headerName: "Last Name", width: 140 },
    { field: "claimedShopName", headerName: "Shop Name", width: 140 },
    { field: "claimedOrderNumber", headerName: "Order Number", width: 140 },
    { field: "affiliateNetwork", headerName: "Affiliate Network", width: 140 },
    {
      field: "claimedOrderDate",
      headerName: "Order Date",
      width: 100,
      type: "date",
      valueFormatter: (params) =>
        new Date(params.value).toLocaleString().split(",")[0],
    },
    {
      field: "createDate",
      headerName: "Create Date",
      width: 100,
      type: "date",
      valueFormatter: (params) =>
        new Date(params.value).toLocaleString().split(",")[0],
    },
    {
      field: "rewardCommissionType",
      headerName: "Reward Commission Type",
      width: 140,
    },
    {
      field: "rewardCurrencyCode",
      headerName: "Reward Currency Code",
      width: 100,
    },
    // { field:'orderValueCurrencyCode', headerName: 'Order Currency Value', width: 140 },

    // { field: 'orderValue', headerName: 'Order Value', width: 140 },

    {
      field: "Ordervalue",
      headerName: "Order value",
      width: 140,
      // valueGetter: (params) => `${params.row.orderValueCurrencyCode} ${params.row.orderValue}`,
      valueGetter: (params) => {
        if (params.row.orderValueCurrencyCode === null) {
          return `${params.row.orderValue}`.replace(".", ",");
        } else {
          return `${params.row.orderValueCurrencyCode} ${params.row.orderValue}`.replace(
            ".",
            ","
          );
        }
      },
    },

    {
      field: "commisionValue",
      headerName: "Commision Value",
      width: 140,
      valueGetter: (params) => {
        if (params.row.commisionValue === null) {
          return params.row.commisionValue;
        } else {
          return `${params.row.commisionValue.toFixed(2)}`.replace(".", ",");
        }
      },
    },
    { field: "rewardCategory", headerName: "Reward Category", width: 140 },
    {
      field: "rewardCommisionPercentage",
      headerName: "Reward Commission Percentage",
      width: 140,
    },
    {
      field: "networkResponse",
      headerName: "Network Response",
      width: 140,
      valueGetter: (params) => {
        if (params.row.networkResponse === null) {
          return 'No Response';
        } else {
          return params.row.networkResponse;
        }
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 140,
    },
  ];

  return (
    <div style={{ height: 500, width: "100%" }}>
      <FilterButtons />

      <Typography
        sx={{ flex: "1 1 100%", alignItems: "center" }}
        variant="h6"
        id="tableTitle"
        component="div"
        className="text-center text-white text-uppercase bg-primary"
      >
        {status === "CANCELLED" ? "REJECTED" : status} Claims
      </Typography>

      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        slots={{
          noRowsOverlay: MyCustomNoRowsOverlay
        }}
      />

      <MissingPurchaseModal
        open={openFormModal}
        toggle={toggle}
        data={missingPurchaseModalData}
        loadData={() => getAllUserClaimByStatus()}
      />
      <Reports open={reportsModal} toggle={toggleReportsModal} />
    </div>
  );
}
