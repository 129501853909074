import React, { useState, useEffect } from "react";
import config from "./utils/config";
import { toast } from "react-toastify";

const PurchaseForm = (props) => {
  const [labelNames, setlabelNames] = useState({});
  useEffect(() => {
    if (config.swedish_partner.includes(props.partnerId)) {
      setlabelNames(config.swedishText);
    } else if (config.danish_partner.includes(props.partnerId)) {
      setlabelNames(config.danishText);
    } else if (config.norweign_partner.includes(props.partnerId)) {
      setlabelNames(config.norwegianText);
    } else if (config.swedish_remember_partner.includes(props.partnerId)) {
      setlabelNames(config.swedish_remember_partner_text);
    } else if (config.norweign_remember_partner.includes(props.partnerId)){
      setlabelNames(config.norweign_remember_partner_text);
    } else if (config.danish_remember_partner.includes(props.partnerId)){
      setlabelNames(config.danish_remember_partner_text);
    } else {
      setlabelNames(config.englishText);
    }
    setTimeout(() => {
      notify();
    }, 1000);
  });

  const notify = () =>
    toast.success(labelNames["purchase_registered"], {
      toastId: "success1",
    });

  return (
    <section
      id="form-total-p-0"
      role="tabpanel"
      aria-labelledby="form-total-h-0"
      className="wizard-content"
      aria-hidden="false"
    >
      <div
        className={
          config.gold_color_partners.includes(props.partnerId)
            ? "reward-support-form-heading"
            : "steps-heading"
        }
        style={props.myTextStyle}
      >
        <div>
          {config.gold_color_partners.includes(props.partnerId) ? (
            <h1>{labelNames["Purchase Details"]}</h1>
          ) : (
            <h5>{labelNames["Purchase Details"]}</h5>
          )}
        </div>
      </div>
      <div
        className={
          config.gold_color_partners.includes(props.partnerId)
            ? "reward-support-form-item"
            : "row mb-3"
        }
      >
        <label htmlFor="colFormLabel" className="col-sm-2 col-form-label">
          {labelNames["Purchase ID"]}:
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            // className="form-control"
            id="purchaseId"
            disabled
            value={props.purchaseData.activityId}
          />
        </div>
      </div>
      <div
        className={
          config.gold_color_partners.includes(props.partnerId)
            ? "reward-support-form-item"
            : "row mb-3"
        }
      >
        <label htmlFor="colFormLabel" className="col-sm-2 col-form-label">
          {labelNames["Shop Name"]}:
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            // className="form-control"
            id="shopName"
            disabled
            value={props.purchaseData.shopName}
          />
        </div>
      </div>
      <div
        className={
          config.gold_color_partners.includes(props.partnerId)
            ? "reward-support-form-item"
            : "row mb-3"
        }
      >
        <label htmlFor="colFormLabel" className="col-sm-2 col-form-label">
          {labelNames["Purchase Date"]}:
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            // className="form-control"
            id="purchaseDate"
            disabled
            value={new Date(props.purchaseData.timeStamp).toLocaleString()}
          />
        </div>
      </div>
      <div
        className={
          config.gold_color_partners.includes(props.partnerId)
            ? "reward-support-form-item"
            : "row mb-3"
        }
      >
        <label htmlFor="colFormLabel" className="col-sm-2 col-form-label">
          {labelNames["Order Number"]}:
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            // className="form-control"
            id="orderNumber"
            disabled
            value={props.purchaseData.orderNumer}
          />
        </div>
      </div>
      <div
        className={
          config.gold_color_partners.includes(props.partnerId)
            ? "reward-support-form-item"
            : "row mb-3"
        }
      >
        <label htmlFor="colFormLabel" className="col-sm-2 col-form-label">
          {labelNames["Order Value"]}:
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            // className="form-control"
            id="orderValue"
            disabled
            value={props.purchaseData.totalPurchaseAmount}
          />
        </div>
      </div>
      <div
        className={
          config.gold_color_partners.includes(props.partnerId)
            ? "reward-support-form-item"
            : "row mb-3"
        }
        style={{ marginBottom: "20px" }}
      >
        <label htmlFor="colFormLabel" className="col-sm-2 col-form-label">
          {labelNames["Expected Commission"]}:
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            // className="form-control"
            id="expectedCommission"
            disabled
            value={props.purchaseData.cashBackAmount}
          />
        </div>
      </div>
    </section>
  );
};

export default PurchaseForm;


